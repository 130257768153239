import React, { useEffect } from 'react';
import './styles.css';
import workflowImage from './workflow.svg'; // Add relevant paths to your images
import consultingImage from './consulting.svg';
import chatbotImage from './chatbot.svg';

function Services() {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const id = hash.replace('#', ''); // Remove the '#' from the hash
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="services-container">
            <div id="ai-workflow-automation" className="service-section">
                <img src={workflowImage} alt="AI Workflow Automation" className="service-image" />
                <div className="service-content">
                    <h2>AI Workflow Automation</h2>
                    <p>Enhance your business operations with our cutting-edge AI Workflow Automation solutions. Streamline processes, reduce human error, and increase efficiency by implementing custom AI tools that suit your business needs. Our services include: </p>

                    <table border="1">
                        <tr>
                            <th>Industry</th>
                            <th>Product</th>
                        </tr>
                        <tr>
                            <td>General</td>
                            <td>Chatbot for customer service</td>
                        </tr>
                        <tr>
                            <td>Retail</td>
                            <td>Auto messaging app</td>
                        </tr>
                        <tr>
                            <td>Gaming</td>
                            <td>NPC training</td>
                        </tr>
                        <tr>
                            <td>Finance</td>
                            <td>Data Analytics/Report Automation</td>
                        </tr>
                        <tr>
                            <td>Manufacturing</td>
                            <td>Quality Control</td>
                        </tr>
                        <tr>
                            <td>Services</td>
                            <td>Drones for inspection</td>
                        </tr>
                        <tr>
                            <td>Education</td>
                            <td>AI Tutor</td>
                        </tr>
                        <tr>
                            <td>Healthcare</td>
                            <td>Diagnostic AI Tool</td>
                        </tr>
                        <tr>
                            <td>Marketing</td>
                            <td>Automated Social Media Posts</td>
                        </tr>
                        </table>
                </div>
            </div>
            <div id="ai-consulting-expertise" className="service-section">
                <div className="service-content">
                    <h2>AI Consulting Expertise</h2>
                    <p>Our AI consulting experts are here to help you harness the power of AI. From strategy to implementation, we guide you through every step of your AI journey.</p>
                </div>
                <img src={consultingImage} alt="AI Consulting Expertise" className="service-image" />
            </div>
            <div id="ai-chatbot-solutions" className="service-section">
                <img src={chatbotImage} alt="AI Chatbot Solutions" className="service-image" />
                <div className="service-content">
                    <h2>AI Chatbot Solutions</h2>
                    <p>Transform customer interactions with our AI Chatbot Solutions. Provide instant support, improve customer satisfaction, and boost engagement with intelligent chatbots.</p>
                </div>
            </div>
        </div>
    );
}

export default Services;
