import React, { useEffect } from 'react';
import './styles.css';

function Terms() {
    return (
        <div className="terms">
        <h1>Terms of Service</h1>

    <h2>1. Acceptance of Terms</h2>
    <p>By accessing and using our services, you accept and agree to be bound by the terms and provision of this agreement. Also, when using these particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</p>

    <h2>2. Description of Service</h2>
    <p>Konvex AI is providing users with [describe services provided]. You must provide all equipment necessary for your own Internet connection, including computer and modem, and provide for your access to the Internet, and pay any fees relate to such connection.</p>

    <h2>3. User Conduct</h2>
    <p>You understand that all information, data, text, photographs, graphics, messages, or other materials ("Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you, and not Konvex AI, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via the Service. Konvex AI does not control the Content posted via the Service and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using the Service, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will Konvex AI be liable in any way for any Content, including, but not limited to, for any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the Service.</p>

    <h2>4. Modifications to Service</h2>
    <p>Konvex AI reserves the right to modify or discontinue the Service with or without notice to the user. Konvex AI shall not be liable to user or any third party should Konvex AI exercise its right to modify or discontinue the Service. User acknowledges and accepts that Konvex AI does not guarantee continuous, uninterrupted or secure access to our website and operation of our website may be interfered with or adversely affected by numerous factors or circumstances outside of our control.</p>

    <h2>5. Termination</h2>
    <p>You agree that Konvex AI, in its sole discretion, may terminate your password, account (or any part thereof) or use of the Service, and remove and discard any Content within the Service, for any reason, including, without limitation, for lack of use or if Konvex AI believes that you have violated or acted inconsistently with the letter or spirit of the TOS. Konvex AI may also in its sole discretion and at any time discontinue providing the Service, or any part thereof, with or without notice. You agree that any termination of your access to the Service under any provision of this TOS may be effected without prior notice, and acknowledge and agree that Konvex AI may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Service. Further, you agree that Konvex AI shall not be liable to you or any third-party for any termination of your access to the Service.</p>

    <h2>6. Links</h2>
    <p>The Service may provide, or third parties may provide, links to other World Wide Web sites or resources. Because Konvex AI has no control over such sites and resources, you acknowledge and agree that Konvex AI is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any Content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that Konvex AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such Content, goods or services available on or through any such site or resource.</p>

    <h2>7. Indemnity</h2>
    <p>You agree to indemnify and hold Konvex AI, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, post, transmit or make available through the Service, your use of the Service, your connection to the Service, your violation of the TOS, or your violation of any rights of another.</p>

    <h2>8. Disclaimer of Warranties</h2>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
    <ul>
        <li>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. Konvex AI EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
        <li>Konvex AI MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.</li>
    </ul>

    <h2>9. Limitation of Liability</h2>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT Konvex AI SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF Konvex AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</p>

    <h2>10. General Information</h2>
    <p>The TOS constitutes the entire agreement between you and Konvex AI and governs your use of the Service, superseding any prior agreements between you and Konvex AI. You also may be subject to additional terms and conditions that may apply when you use or purchase certain other Konvex AI services, affiliate services, third-party content or third-party software. The TOS and the relationship between you and Konvex AI shall be governed by the laws of the State of Florida without regard to its conflict of law provisions. You and Konvex AI agree to submit to the personal and exclusive jurisdiction of the courts located within the county of [Your County], Florida. The failure of Konvex AI to exercise or enforce any right or provision of the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the TOS remain in full force and effect.</p>

    <h2>11. Violations</h2>
    <p>Please report any violations of the TOS to our support team at jimbmendez@gmail.com.</p>

    <h2>12. Contact Information</h2>
    <p>If you have any questions about these Terms, please contact us at:</p>
    <p>Konvex AI, FL, USA</p>
    </div>
    );
}

export default Terms;
