import React, { useEffect } from 'react';
import './styles.css';

function Privacy() {
    return (
        <div className="privacy">
            <h1>Privacy Policy</h1>

    <h2>1. Introduction</h2>
    <p>Welcome to Konvex AI. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at jimbmendez@gmail.com.</p>

    <h2>2. Information We Collect</h2>
    <p>We collect personal information that you provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the website, or otherwise contacting us.</p>
    <p>The personal information that we collect depends on the context of your interactions with us and the website, the choices you make, and the products and features you use. The personal information we collect can include the following:</p>
    <ul>
        <li>Name and Contact Data. We collect your first and last name, email address, postal address, phone number, and other similar contact data.</li>
        <li>Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.</li>
        <li>Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</p>
    <ul>
        <li>To facilitate account creation and logon process.</li>
        <li>To send you marketing and promotional communications.</li>
        <li>To send administrative information to you.</li>
        <li>Fulfill and manage your orders.</li>
        <li>To post testimonials.</li>
        <li>Deliver targeted advertising to you.</li>
        <li>Request Feedback.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations. We may process or share data based on the following legal basis:</p>
    <ul>
        <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
        <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
        <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
        <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
    </ul>

    <h2>5. Cookies and Tracking Technologies</h2>
    <p>We may use cookies and similar tracking technologies to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>

    <h2>6. Data Security</h2>
    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>

    <h2>7. Your Privacy Rights</h2>
    <p>Depending on your location, you may have certain rights under applicable data protection laws. These may include the right to:</p>
    <ul>
        <li>Request access and obtain a copy of your personal information.</li>
        <li>Request rectification or erasure.</li>
        <li>Restrict the processing of your personal information.</li>
        <li>If applicable, to data portability.</li>
        <li>If applicable, to withdraw consent.</li>
    </ul>

    <h2>8. Changes to This Policy</h2>
    <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

    <h2>9. Contact Us</h2>
    <p>If you have questions or comments about this policy, you may contact us at:</p>
    <p>Konvex AI, FL, USA</p>
    </div>
    );
}

export default Privacy;
