import React from 'react';
import Home from './Home';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from './NavBar';
import Services from './Services';
import Contact from './Contact';
import Privacy from './Privacy';
import Terms from './Terms';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop /> {/* Add the ScrollToTop component */}
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Konvex" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} /> 
          </Routes>
        </div>
        <div className="bottom-banner">
          <Link to="/privacy">Privacy Policy</Link> {/* Use Link for client-side navigation */}
          <Link to="/terms">Terms of Service</Link> {/* Update this when Terms component is available */}
        </div>
      </Router>
    </div>
  );
}

export default App;
