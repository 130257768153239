import React, { useEffect } from 'react';
import './styles.css';

function Contact() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="content-contact">
            <div className="contact-message">
                <h2>Reach Out to Us for Expert AI Consulting Solutions</h2>
                <p>Have an AI project idea, question, or just want to reach out? Fill out the contact form and we will be back in touch with you shortly. If inquiring about a project, include budget and time constraints that may apply. We are looking forward to working with you to streamline your business!</p>
            </div>
            <div className="contact-form">
                <iframe 
                    data-tally-src="https://tally.so/embed/n911MQ?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
                    loading="eager" 
                    width="200%" 
                    height="276" 
                    frameBorder="0" 
                    marginHeight="0" 
                    marginWidth="0" 
                    title="Contact">
                </iframe>
            </div>
        </div>
    );
}

export default Contact;
