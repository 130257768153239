import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import scrollImage from './scroll.svg';

function Home() {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.async = true;
        script.id = 'tally-widget-script';
    
        document.body.appendChild(script);
    
        return () => {
            const scriptElement = document.getElementById('tally-widget-script');
            if (scriptElement) {
                document.body.removeChild(scriptElement);
            }
        };
    }, []);
    

    const handleServicesClick = () => {
        navigate('/services');
    };

    const handleServiceBoxClick = (targetId) => {
        navigate(`/services#${targetId}`);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="home-container">
                <div className="banner">
                    <h1>
                        Empower Your Operations with <span className="highlight">Next-Gen AI Innovations</span>
                    </h1>
                </div>
                <div className="buttons">
                    <button className="button" data-tally-open="wLGGkv" data-tally-layout="modal" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
                        Let's Talk
                    </button>
                    <button className="button2" onClick={handleServicesClick}>Our Services</button>
                </div>
            </div>
            <div className={`arrow ${isScrolled ? 'hide' : ''}`}>
                <img src={scrollImage} alt="scroll arrow" className="scroll-image" />
            </div>
            <div className="service-header">
                <h1>What We Do</h1>
            </div>
            <div className="home-services">
                <div className="service-boxes">
                    <button className="service-box" onClick={() => handleServiceBoxClick('ai-workflow-automation')}>
                        <div className="service-box-1">
                        <h3>AI Workflow Automation</h3>
                        </div>
                    </button>
                    <button className="service-box" onClick={() => handleServiceBoxClick('ai-consulting-expertise')}>
                        <div className="service-box-2">
                        <h3>AI Consulting Expertise</h3>
                        </div>
                    </button>
                    <button className="service-box" onClick={() => handleServiceBoxClick('ai-chatbot-solutions')}>
                        <div className="service-box-3">
                        <h3>AI Chatbot Solutions</h3>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Home;
