import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles.css';
import logo from './logo.png'; // Adjust the path based on your project structure

function Navbar() {
    const location = useLocation();
    const isServicesPage = location.pathname === '/services';
    const isOtherPage = location.pathname === '/terms' || location.pathname === '/privacy';

    return (
        <div className={`navbar ${isServicesPage ? 'navbar-services' : ''} ${isOtherPage ? 'navbar-other' : ''}`}>
            <div className="logo-div">
                <Link to= "home">
                    <img src={logo} alt="Konvex Logo" className="logo" />
                </Link>
            </div>
            <div className="nav-buttons">
                <Link to="/home">Home</Link>
                <Link to="/services">Services</Link>
                <Link to="/contact">Contact Us</Link>
            </div>
        </div>
    );
}

export default Navbar;
